<template>
    <ul
        role="list"
        class="-mx-2 space-y-1"
    >
        <li>
            <nuxt-link
                to="/organization"
                class="nav-item-dark"
                active-class="active"
            >
                <HomeIconOutline class="icon" />
                Dashboard
            </nuxt-link>
        </li>
        <li>
            <nuxt-link
                to="/organization/creches"
                class="nav-item-dark"
                active-class="active"
            >
                <BuildingOffice2IconOutline class="icon" />
                Structures d'accueil
            </nuxt-link>
        </li>
        <li>
            <nuxt-link
                to="/organization/registrations"
                class="nav-item-dark"
                active-class="active"
            >
                <BookOpenIconOutline class="icon" />
                Inscriptions
            </nuxt-link>
        </li>
        <li>
            <nuxt-link
                to="/organization/calendar"
                class="nav-item-dark"
                active-class="active"
            >
                <CalendarDaysIconOutline class="icon" />
                Calendrier
            </nuxt-link>
        </li>
        <li>
            <nuxt-link
                to="/organization/parents"
                class="nav-item-dark"
                active-class="active"
            >
                <UsersIconOutline class="icon" />
                Parents
            </nuxt-link>
        </li>
        <li>
            <nuxt-link
                to="/organization/staff"
                class="nav-item-dark"
                active-class="active"
            >
                <Icon name="streamline:office-worker" />
                Personnel
            </nuxt-link>
        </li>
        <li>
            <UiDropdown>
                <template #trigger="{ open }">
                    <button
                        class="nav-item-dark w-full"
                    >
                        <ChatBubbleLeftRightIconOutline class="icon" />
                        Communication
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/organization/messages"
                            class="nav-item-dark pl-10"
                        >
                            Emails
                        </nuxt-link>
                        <nuxt-link
                            to="/organization/social"
                            class="nav-item-dark pl-10"
                        >
                            Publications
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>
        <li>
            <nuxt-link
                to="/organization/invoices"
                class="nav-item-dark"
                active-class="active"
            >
                <CurrencyEuroIconOutline class="icon" />
                Factures
            </nuxt-link>
        </li>
        <li>
            <nuxt-link
                to="/organization/settings"
                class="nav-item-dark"
                active-class="active"
            >
                <AdjustmentsHorizontalIconOutline class="icon" />
                Paramètres
            </nuxt-link>
        </li>
        <li>
            <UiDropdown>
                <template #trigger="{ open }">
                    <button
                        class="nav-item-dark w-full"
                    >
                        <PresentationChartBarIconOutline class="icon" />
                        Rapports
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            v-if="$user.creches.filter(c => c.type === 'nursery').length > 0"
                            to="/organization/reports/sae"
                            class="nav-item-dark pl-10"
                        >
                            Accueillantes (SAE)
                        </nuxt-link>
                        <nuxt-link
                            v-if="$user.creches.filter(c => c.type === 'nursery').length > 0"
                            to="/organization/reports/sae/pro-one"
                            class="nav-item-dark pl-10"
                        >
                            PRO ONE (SAE)
                        </nuxt-link>
                        <nuxt-link
                            v-if="$user.creches.filter(c => c.type === 'aes').length > 0"
                            to="/organization/reports/aes"
                            class="nav-item-dark pl-10"
                        >
                            Extra Scolaire (AES)
                        </nuxt-link>
                        <nuxt-link
                            to="/organization/reports/billing"
                            class="nav-item-dark pl-10"
                        >
                            Rapports Financiers
                        </nuxt-link>
                        <nuxt-link
                            to="/organization/reports/attendances"
                            class="nav-item-dark pl-10"
                        >
                            Rapports de présences
                        </nuxt-link>
                        <nuxt-link
                            to="/organization/reports/remunerations"
                            class="nav-item-dark pl-10"
                        >
                            Prix journaliers
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>
    </ul>
</template>

<script setup lang="ts">
</script>
