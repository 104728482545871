<template>
    <div class="min-h-screen">
        <div
            class="relative z-10 lg:hidden"
            role="dialog"
            aria-modal="true"
        >
            <Transition>
                <div
                    v-if="mobileMenuOpen"
                    class="fixed inset-0 bg-gray-900/80"
                    @click="mobileMenuOpen = false"
                />
            </Transition>

            <div
                class="fixed inset-y-0 mr-16 z-20 flex w-full max-w-xs flex-1 transition-all"
                :class="{
                    'translate-x-0': mobileMenuOpen,
                    '-translate-x-full': !mobileMenuOpen
                }"
            >
                <Transition>
                    <div
                        v-if="mobileMenuOpen"
                        class="absolute left-full top-0 flex w-16 justify-center pt-5"
                    >
                        <button
                            type="button"
                            class="-m-2.5 p-2.5"
                            @click="mobileMenuOpen = false"
                        >
                            <span class="sr-only">Close sidebar</span>
                            <XMarkIconMini class="h-6 w-6 text-white" />
                        </button>
                    </div>
                </Transition>

                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div class="flex h-16 shrink-0 items-center">
                        <img
                            class="h-8 w-auto"
                            src="https://abcreche.com/img/icon.png"
                            alt="ABCreche"
                        >
                    </div>
                    <nav
                        class="flex flex-1 flex-col"
                        aria-label="primary-navigation"
                    >
                        <ul
                            role="list"
                            class="flex flex-1 flex-col gap-y-7"
                        >
                            <li>
                                <OrganizationNavigation />
                            </li>

                            <li class="mt-auto">
                                <img
                                    class="h-10 mb-4 w-auto mx-auto"
                                    :src="$user.organization.logo_path"
                                    :alt="$user.organization.name"
                                >
                                <nuxt-link
                                    v-if="$user.creches.length"
                                    to="/"
                                    class="nav-item-dark items-center"
                                >
                                    <ArrowsRightLeftIconMini class="icon" />
                                    Retourner à {{ $creche.name }}
                                </nuxt-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-64 lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
                <div class="flex h-16 shrink-0 items-center">
                    <img
                        class="h-8 w-auto"
                        src="https://abcreche.com/img/icon.png"
                        alt="ABCreche"
                    >
                </div>
                <nav
                    class="flex flex-1 flex-col"
                    aria-label="Sidebar"
                >
                    <ul
                        role="list"
                        class="flex flex-1 flex-col gap-y-7"
                    >
                        <li>
                            <OrganizationNavigation />
                        </li>
                        <li class="mt-auto">
                            <img
                                class="h-10 mb-4 w-auto mx-auto"
                                :src="$user.organization.logo_path"
                                :alt="$user.organization.name"
                            >
                            <nuxt-link
                                v-if="$user.creches.length"
                                to="/"
                                class="nav-item-dark items-center"
                            >
                                <ArrowsRightLeftIconMini class="icon" />
                                Retourner à {{ $creche.name }}
                            </nuxt-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="lg:pl-64 h-screen flex flex-col">
            <div class="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button
                    type="button"
                    class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    @click="mobileMenuOpen = true"
                >
                    <span class="sr-only">Open sidebar</span>
                    <Bars3IconSolid class="h-6 w-6" />
                </button>

                <!-- Separator -->
                <div
                    class="h-6 w-px bg-gray-900/10 lg:hidden"
                    aria-hidden="true"
                />

                <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <OrganizationTopSearch />
                    <div class="flex items-center space-x-4">
                        <UserNotifications />
                        <UserMessages />

                        <div class="h-16 flex items-center support-modal">
                            <UiButtonModal
                                id="support-modal"
                                size="5xl"
                            >
                                <template #trigger>
                                    <button
                                        type="button"
                                        class="text-gray-400 relative transition-colors hover:text-gray-500 group"
                                    >
                                        <LifebuoyIconOutline class="size-6" />
                                    </button>
                                </template>
                                <AppSupport />
                            </UiButtonModal>
                        </div>

                        <!-- Separator -->
                        <div
                            class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                            aria-hidden="true"
                        />

                        <!-- Profile dropdown -->
                        <UserDropdown v-if="$user" />
                    </div>
                </div>
            </div>

            <main class="py-10 grow bg-slate-50 overflow-auto">
                <div class="px-4 sm:px-6 lg:px-8">
                    <div class="relative">
                        <suspense>
                            <slot />
                        </suspense>
                    </div>
                </div>
            </main>

            <UiDialog />
            <UiToastWrapper />
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '~/stores/auth'

export default {
    data () {
        return {
            mobileMenuOpen: false
        }
    },

    computed: {
        ...mapState(useAuthStore, ['user'])
    },

    watch: {
        $route () {
            this.mobileMenuOpen = false
        }
    }
}
</script>
